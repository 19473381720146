import * as amplitude from '@amplitude/analytics-browser'

export const amplitudeAnalytics = (): void => {
  if (process.env.NODE_ENV === 'development') return

  const enrichProjectPlugin = (): any => {
    return {
      execute: async (event: any) => {
        if (event.event_type === '$identify') return event

        // Make event names project specific to follow common conventions
        let eType: string = event.event_type.replace('[Amplitude] ', '')
        eType = eType.replace(' ', '_')
        event.event_type = 'MKTG_Site_' + eType

        event.event_properties = {
          ...event.event_properties,
          'Page Path': window.location.pathname,
          'Page URL': window.location.href,
          service: 'marketing_website'
        }

        return event
      }
    }
  }
  amplitude.add(enrichProjectPlugin())

  amplitude.init(process.env.GATSBY_AMPLITUDE_API_KEY, {
    defaultTracking: true
  })

  // Capture Link Clicks
  window.addEventListener('click', (event: Event) => {
    let t

    if (event.target instanceof HTMLAnchorElement) {
      t = event.target
    } else if ((event.target as HTMLElement)?.parentElement instanceof HTMLAnchorElement) {
      if (event.target === null) return

      // Some instances we wrap buttons in a tags
      t = (event.target as HTMLElement).parentElement
    } else {
      return
    }

    const payload = {
      href: t.href,
      text: t.text,
      id: t.id,
      hash: t.hash,
      hostname: t.hostname,
      className: t.className,
      trigger: 'Interaction'
    }

    if (t.hostname !== window.location.hostname) {
      amplitude.setTransport('beacon')
      amplitude.track('Outbound_link_clicked', payload)
      amplitude.flush()
    } else if (t.hash !== undefined && t.hash !== '') {
      amplitude.track('Anchor_link_clicked', payload)
    }
  })

  window.addEventListener('pagehide', () => {
    amplitude.setTransport('beacon')
    amplitude.flush()
  })
}
